export default [
    {
        name: 'Medium',
        rows: 20,
        columns: 10,
        bombs: 35,
        bisque: true // lambuja
    },
    {
        name: 'Easy',
        rows: 12,
        columns: 6,
        bombs: 10,
        bisque: true // lambuja
    },
    {
        name: 'Hard',
        rows: 28,
        columns: 13,
        bombs: 75,
        bisque: true // lambuja
    }
];
